import React from 'react';
import PropTypes from 'prop-types';

function IconButton(props) {
  const {
    className, handleClick, icon, name,
  } = props;
  return (
    <button
      type="button"
      name={name}
      className={`${className} focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center`}
      onClick={handleClick}
    >
      <img src={icon} alt="icon" />
    </button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
};

export default IconButton;
