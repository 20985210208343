import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';
import { fetchOrders, updateOrder } from '../../../../stores/slices/orderSlice';
import { setToastShow } from '../../../../stores/slices/toastSlice';

function ActionModal(props) {
  const dispatch = useDispatch();
  const { modal, onCloseModal, item } = props;
  const [status, setStatus] = useState('');
  const { orderLoading } = useSelector((state) => state.orders);
  const statusOptions = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'In Transit', label: 'In Transit' },
    { value: 'Received', label: 'Received' },
  ];
  useEffect(() => {
    setStatus(item.status);
  }, [item]);
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const onSaveForm = () => {
    dispatch(updateOrder({ id: item.id, status }))
      .unwrap()
      .then(() => {
        dispatch(fetchOrders());
        dispatch(
          setToastShow({
            toastShow: true,
            toastIsError: false,
            toastMessage: 'successfuly update order status',
          }),
        );
        onCloseModal();
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      });
  };

  return (
    <Modal show={modal}>
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6">
            <h3 className="mb-5 text-center text-lg font-normal text-gray-500 dark:text-gray-400 capitalize">
              Change Status
            </h3>
            <Select
              label="Status"
              id="status"
              name="status"
              value={status}
              handleChange={handleStatusChange}
              lists={statusOptions}
            />
            <div className="flex justify-center mt-4">
              <Button
                name="saveModal"
                className="text-white bg-red-600 hover:bg-red-800"
                isLoading={orderLoading}
                isDisabled={orderLoading}
                handleClick={onSaveForm}
              >
                Change Status
              </Button>

              <Button
                name="closeModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default ActionModal;
