import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '../../components/Container';
import { fetchMenu } from '../../stores/slices/menuSlice';
import loader from '../../assets/svgs/loader-black.svg';
import reply from '../../assets/svgs/reply.svg';
import noData from '../../assets/svgs/no-data.svg';
import Button from '../../components/Button';
import { fetchReviews } from '../../stores/slices/reviewSlice';
import IconButton from '../../components/IconButton';
import ReviewCard from '../../components/ReviewCard';

function Review() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menu, menusError, menusLoading } = useSelector((state) => state.menus);
  const { reviews, reviewsLoading } = useSelector((state) => state.reviews);

  useEffect(() => {
    dispatch(fetchMenu({ id: params.id }));
    dispatch(fetchReviews({ id: params.id }));
  }, []);

  return (
    <Container>
      {(menusLoading || reviewsLoading) && (
        <div className="h-screen w-[100%]">
          <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
        </div>
      )}
      {menusError && (
        <div className="h-screen w-[100%] py-20">
          <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
          <p className="text-center mt-4">Menu Not Found</p>
          <Button
            className="mt-8 text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            name="back"
            handleClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            Go Back
          </Button>
        </div>
      )}
      {!menusLoading && !menusError && !reviewsLoading && (
        <>
          <IconButton
            name="backButton"
            className="mt-[24px] bg-gray-100 hover:bg-gray-200"
            icon={reply}
            handleClick={() => {
              navigate(-1, { replace: true });
            }}
          />
          <h1 className="mt-4 text-3xl font-bold">
            {menu?.data?.name}
            {' '}
            Reviews
          </h1>
          <h2 className="mt-3 text-xl">
            Rating:
            {' '}
            {menu?.data?.avg_rating}
          </h2>
          {(reviews !== null && reviews.length > 0) ? (
            <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {reviews.map((x) => (
                <ReviewCard key={x.id} item={x} />
              ))}
            </div>
          ) : (
            <div className="py-20">
              <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
              <p className="text-center mt-4">No Reviews</p>
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default Review;
