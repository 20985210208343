import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToastShow } from '../../stores/slices/toastSlice';

function Toast() {
  const { toastShow, toastIsError, toastMessage } = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  const setTime = () => {
    setTimeout(() => {
      dispatch(setToastShow({ toastShow: false, toastIsError: false, toastMessage: '' }));
    }, 3000);
  };

  useEffect(() => {
    setTime();
  }, [toastShow]);

  return (
    <div
      className={`${toastShow ? 'flex ' : 'hidden '} ${
        toastIsError ? 'bg-red-500 text-white ' : 'bg-green-500 text-white '
      } absolute z-40 top-5 right-5 items-center p-4 space-x-4 w-full max-w-xs rounded-lg divide-x divide-gray-200 shadow space-x`}
      role="alert"
    >
      <div className="text-sm font-normal capitalize">{toastMessage}</div>
    </div>
  );
}

export default Toast;
