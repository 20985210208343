import axios from 'axios';
import Cookies from 'js-cookie';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 3600 * 60,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const waitForStorageToBeReady = (config) => {
  const newConfig = config;
  const token = Cookies.get('TOKEN');
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  return newConfig;
};

// request interceptor
client.interceptors.request.use(
  (config) => waitForStorageToBeReady(config),
  (requestError) => Promise.reject(requestError),
);

// response interceptor
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('TOKEN');
      if (window.location.pathname !== '/login') {
        window.location = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export default client;
