import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImg from '../../assets/svgs/not-found.svg';
import Container from '../../components/Container';

function NotFound() {
  return (
    <Container>
      <div className="w-[100wh] h-[100vh] flex flex-col items-center justify-center">
        <img className="w-[500px]" src={notFoundImg} alt="Not Found" />
        <h1 className="text-center text-3xl mt-4">Page Not Found</h1>
        <Link
          className="w-full mt-8 text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
          to="/"
        >
          <p className="text-center">Back To Home</p>
        </Link>
      </div>
    </Container>
  );
}

export default NotFound;
