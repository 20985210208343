import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

function DeleteModal(props) {
  const {
    modalDelete, onCloseDelete, onDelete, item,
  } = props;

  const { menusLoading } = useSelector((state) => state.menus);

  return (
    <Modal show={modalDelete}>
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseDelete}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <svg
              aria-hidden="true"
              className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete
              {' '}
              {item.name}
              ?
            </h3>
            <div className="flex justify-center">
              <Button
                name="deleteModal"
                className="text-white bg-red-600 hover:bg-red-800"
                isLoading={menusLoading}
                isDisabled={menusLoading}
                handleClick={onDelete}
              >
                Yes, Im sure
              </Button>
              <Button
                name="closeDeleteModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseDelete}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  modalDelete: PropTypes.bool.isRequired,
  onCloseDelete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default DeleteModal;
