import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Container from '../../components/Container';
import Select from '../../components/Select';
import { fetchOrders } from '../../stores/slices/orderSlice';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import convertToRupiah from '../../helpers/convertToRupiah';
import Button from '../../components/Button';
import ActionModal from './components/ActionModal';

function Order() {
  const dispatch = useDispatch();
  const { orders, orderError, orderLoading } = useSelector((state) => state.orders);
  const [filter, setFilter] = useState('In Progress');
  const [mappedOrders, setMappedOrders] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState({});
  const filterOptions = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'In Transit', label: 'In Transit' },
    { value: 'Received', label: 'Received' },
  ];

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const mapOrders = () => {
    let tempData = [];
    if (orders !== null && orders.length > 0) {
      tempData = orders;
      // filter by status
      tempData = tempData.filter((x) => x.status === filter);
      if (filter === 'Received') {
        tempData.reverse();
      }
    }
    setMappedOrders(tempData);
  };

  const handleChangeStatusClick = (itemClicked) => {
    setItem(itemClicked);
    setModal(true);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  useEffect(() => {
    mapOrders();
  }, [orders, filter]);

  useEffect(() => {});
  return (
    <Container>
      <h1 className="mt-10 text-3xl font-bold">Order</h1>
      <div className="mt-8">
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          {orderLoading && (
            <div className="absolute h-screen w-[100%] left-0">
              <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
            </div>
          )}
          {orderError && (
            <div className="h-screen w-[100%] py-20">
              <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
              <p className="text-center mt-4">Something went wrong</p>
            </div>
          )}
          {!orderError && (
            <div className="w-40">
              <Select
                label="Status"
                id="filter"
                name="filter"
                value={filter}
                handleChange={handleFilterChange}
                lists={filterOptions}
              />
            </div>
          )}
          {!orderError && !orderLoading && (
            <div className="my-4">
              {mappedOrders.length > 0 ? (
                <>
                  <ActionModal modal={modal} item={item} onCloseModal={onCloseModal} />
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                    {mappedOrders.map((x) => {
                      const total = x.total_price - x.discount_price;
                      return (
                        <div key={x.id} className="p-4 w-full bg-white rounded-lg border shadow-md sm:p-8">
                          <div className="flex flex-col md:flex-row justify-start  items-start md:justify-between md:items-center mb-4">
                            <h5 className="text-xl font-bold leading-none text-gray-900">
                              {x.user.fullname}
                            </h5>
                            <h5 className="text-sm font-bold leading-none text-gray-900">
                              {moment(x.order_date).format('DD MMMM YYYY - HH:mm')}
                            </h5>
                            {x.status === 'In Progress' && (
                              <p className="text-sm font-medium text-gray-600">{x.status}</p>
                            )}
                            {x.status === 'In Transit' && (
                              <p className="text-sm font-medium text-blue-600">{x.status}</p>
                            )}
                            {x.status === 'Received' && (
                              <p className="text-sm font-medium text-green-600">{x.status}</p>
                            )}
                          </div>
                          <div className="flex justify-start items-start">
                            <Button
                              name="updateStatus"
                              className="w-[1000%] md:w-[40%]"
                              handleClick={() => {
                                handleChangeStatusClick(x);
                              }}
                            >
                              Change Status
                            </Button>
                          </div>
                          <div className="flow-root">
                            <ul className="divide-y divide-gray-200">
                              {x.order_details.map((detail) => {
                                const option = JSON.parse(detail.options);
                                return (
                                  <li key={detail.id} className="py-3 sm:py-4">
                                    <div className="flex flex-col md:flex-row items-start md:items-center md:space-x-4">
                                      <div className="flex-shrink-0">
                                        <img
                                          className="w-20 h-20 max-w-[80px] rounded-lg object-contain"
                                          src={detail.menu.photo_url}
                                          alt="food"
                                        />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 text-clip">
                                          {detail.menu.name}
                                          {' '}
                                          x
                                          {detail.quantity}
                                        </p>
                                        {detail.menu.menu_category.name !== '' && (
                                          <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                                            {detail.menu.menu_category.name}
                                          </span>
                                        )}
                                        <p className="text-xs text-gray-500 text-clip">
                                          {option[0].value}
                                          {' '}
                                          +Rp.
                                          {' '}
                                          {convertToRupiah(option[0].additional_price)}
                                        </p>
                                        <p className="text-sm text-gray-500 text-clip">
                                          Note:
                                          {' '}
                                          {detail.note}
                                        </p>
                                      </div>
                                      <div className="inline-flex items-center text-base font-semibold text-gray-900">
                                        Rp.
                                        {' '}
                                        {convertToRupiah(detail.price)}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                              <div className="flex flex-col">
                                <div className="flex flex-row justify-between mt-2">
                                  <div className="flex">
                                    <p>Subtotal:</p>
                                  </div>
                                  <div className="flex">
                                    <p>
                                      {' '}
                                      Rp.
                                      {' '}
                                      {convertToRupiah(x.total_price)}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <div className="flex">
                                    <p>Discount:</p>
                                  </div>
                                  <div className="flex text-red-400">
                                    <p>
                                      {' '}
                                      -Rp.
                                      {' '}
                                      {convertToRupiah(x.discount_price)}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between text-lg">
                                  <div className="flex font-bold">
                                    <p>Total:</p>
                                  </div>
                                  <div className="flex font-bold">
                                    <p>
                                      Rp.
                                      {' '}
                                      {total < 0 ? 0 : convertToRupiah(total)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="py-20">
                  <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
                  <p className="text-center mt-4">No Orders</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Order;
