import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noData from '../../assets/svgs/no-data.svg';
import Container from '../../components/Container';
import Select from '../../components/Select';
import Table from '../../components/Table';
import convertToRupiah from '../../helpers/convertToRupiah';
import { fetchOrders } from '../../stores/slices/orderSlice';

function Home() {
  const dispatch = useDispatch();
  const { orders, orderError, orderLoading } = useSelector((state) => state.orders);
  const [filter, setFilter] = useState('all');
  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: '7_days', label: 'Last 7 Days' },
    { value: 'this_month', label: 'This Month' },
    { value: 'last_month', label: 'Last Month' },
  ];

  const rows = [
    {
      title: 'Customer Name',
      column: 'fullname',
    },
    {
      title: 'Date',
      column: 'order_date',
    },
    {
      title: 'Subtotal',
      column: 'total_price',
    },
    {
      title: 'Discount',
      column: 'discount_price',
    },
    {
      title: 'Total',
      column: 'total',
    },
  ];
  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  const mapOrders = () => {
    let tempData = [];
    if (orders !== null && orders.length > 0) {
      tempData = orders;
      // filter by date
      if (filter === '7_days') {
        const dateFrom = moment().subtract(7, 'd');
        tempData = tempData.filter((x) => moment(x.order_date).isAfter(dateFrom));
      } else if (filter === 'this_month') {
        const thisMonth = moment();
        tempData = tempData.filter((x) => moment(x.order_date).isSame(thisMonth, 'month'));
      } else if (filter === 'last_month') {
        const lastMonth = moment().subtract(1, 'month');
        tempData = tempData.filter((x) => moment(x.order_date).isSame(lastMonth, 'month'));
      }
    }

    if (tempData.length === 0) {
      return (
        <tr>
          <td colSpan={rows.length} className="py-8">
            <img className="w-[80px] mx-auto" src={noData} alt="no data" />
            <p className="text-center mt-4">No Data</p>
          </td>
        </tr>
      );
    }

    return tempData.map((itemData) => (
      <tr key={itemData.id} className="bg-white border-b hover:bg-gray-50">
        <td className="py-4 px-6">{itemData.user.fullname}</td>
        <td className="py-4 px-6">{moment(itemData.order_date).format('DD MMMM YYYY - HH:mm')}</td>
        <td className="py-4 px-6">{`Rp. ${convertToRupiah(itemData.total_price)}`}</td>
        <td className="py-4 px-6">{`Rp. ${convertToRupiah(itemData.discount_price)}`}</td>
        <td className="py-4 px-6">
          {`Rp. ${
            itemData.total_price - itemData.discount_price === 0
              ? 0
              : convertToRupiah(itemData.total_price - itemData.discount_price)
          }`}

        </td>
      </tr>
    ));
  };

  useEffect(() => {
    mapOrders();
  }, [filter]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <Container>
      <h1 className="mt-10 text-3xl font-bold">Home</h1>
      <div className="mt-8">
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div className="flex flex-col gap-y-4 pl-2 md:pl-0 md:flex-row justify-start md:justify-between items-start md:items-center pb-4 bg-white dark:bg-gray-900">
            <div className="flex gap-2">
              <Select
                id="sortBy"
                name="sortBy"
                value={filter}
                handleChange={handleFilterChange}
                lists={filterOptions}
              />
            </div>
          </div>
          <Table
            rows={rows}
            renderData={mapOrders}
            page="1"
            total={1}
            onNextClick={() => {}}
            onPreviousClick={() => {}}
            onEditClick={() => {}}
            onDeleteClick={() => {}}
            isLoading={orderLoading}
            error={orderError}
            footer={false}
          />
        </div>
      </div>
    </Container>
  );
}

export default Home;
