import React from 'react';
import PropTypes from 'prop-types';

function Container(props) {
  const { children } = props;
  return <div className="w-[100%] px-5 lg:px-20">{children}</div>;
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Container;
