import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import useMenuForm from '../../index.hooks';
import { createMenus, updateMenus } from '../../../../stores/slices/menuSlice';
import { setToastShow } from '../../../../stores/slices/toastSlice';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import TextArea from '../../../../components/TextArea';
import { fetchAllCategories } from '../../../../stores/slices/menuCategorySlice';

function ActionModal(props) {
  const dispatch = useDispatch();
  const {
    modal, onCloseModal, modalStatus, onSave, initForm, item, initTouched,
  } = props;
  const isAvailableOptions = [
    { value: 'true', label: 'True' },
    { value: 'false', label: 'False' },
  ];
  const {
    form,
    setForm,
    error,
    touched,
    setTouched,
    handleOnBlur,
    handleFormChange,
    validateForm,
  } = useMenuForm();

  const { menusLoading } = useSelector((state) => state.menus);
  const { categoriesAll } = useSelector((state) => state.categories);
  const selectList = [{ value: '', label: 'Choose Category' }];
  const initOptions = [
    {
      id: Date.now(),
      title: 'Size',
      options: [
        {
          id: Date.now(),
          value: '',
          additional_price: 0,
          is_selected: false,
        },
      ],
    },
  ];

  const handleClickAddOption = (id) => {
    const tempOptions = [...form.options];
    const optionItem = tempOptions.find((x) => x.id === id);
    optionItem.options.push({ id: Date.now(), value: '', additional_price: 0 });
    setForm((prevState) => ({ ...prevState, options: [...tempOptions] }));
  };

  const handleClickDeleteOption = (id, index) => {
    const tempOptions = [...form.options];
    const optionItem = tempOptions.find((x) => x.id === id);
    optionItem.options.splice(index, 1);
    setForm((prevState) => ({ ...prevState, options: [...tempOptions] }));
  };

  const handleChangeValue = (id, index, e) => {
    const tempOptions = [...form.options];
    const optionItem = tempOptions.find((x) => x.id === id);
    if (e.target.name === 'additional_price') {
      optionItem.options[index][e.target.name] = parseInt(e.target.value, 10);
    } else {
      optionItem.options[index][e.target.name] = e.target.value;
    }
    setForm((prevState) => ({ ...prevState, options: [...tempOptions] }));
  };

  const renderOptions = () => form.options.map((x) => (
    <div key={`${x.id}--${x.title}`}>
      <div>{x.title}</div>
      <div className="ml-2">
        {x.options.map((opt, index) => {
          const i = index;
          return (
            <div key={`${x.title}--${i}`}>
              <Input
                id={`${x.title}--${index}-value`}
                placeholder="title"
                name="value"
                label="Title"
                type="text"
                value={opt.value}
                handleInputChange={(e) => {
                  handleChangeValue(x.id, index, e);
                }}
              />
              <Input
                id={`${x.title}--${index}-price`}
                placeholder="price"
                name="additional_price"
                label="Additional Price"
                type="number"
                value={opt.additional_price}
                handleInputChange={(e) => {
                  handleChangeValue(x.id, index, e);
                }}
              />
              {x.options.length > 1 ? (
                <Button
                  name="deleteOption"
                  className="bg-white text-gray-500 border border-gray-400 hover:border-red-400 hover:bg-white hover:text-red-400 focus:ring-white"
                  handleClick={() => {
                    handleClickDeleteOption(x.id, index);
                  }}
                >
                  Delete Option
                </Button>
              ) : null}
            </div>
          );
        })}
        <Button
          name="addOption"
          className="bg-white text-gray-500 border border-gray-400 hover:border-black hover:bg-white hover:text-black focus:ring-white"
          handleClick={() => {
            handleClickAddOption(x.id);
          }}
        >
          Add Option
        </Button>
      </div>
      <span className="text-red-400">{touched.options ? error.options : null}</span>
    </div>
  ));

  useEffect(() => {
    if (modalStatus === 'create') {
      setForm({ ...initForm, options: initOptions });
    } else {
      setForm(initForm);
    }
    setTouched(initTouched);
  }, [initForm]);

  useEffect(() => {
    dispatch(fetchAllCategories({ sort: '', sortBy: '', search: '' }));
  }, []);

  const onSaveForm = () => {
    if (validateForm(modalStatus)) {
      if (modalStatus === 'create') {
        dispatch(createMenus({ form }))
          .unwrap()
          .then(() => {
            dispatch(
              setToastShow({
                toastShow: true,
                toastIsError: false,
                toastMessage: `${form.name} created`,
              }),
            );
            return onSave(true);
          })
          .catch((err) => {
            dispatch(
              setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
            );
            return onSave(false);
          });
      } else {
        dispatch(updateMenus({ id: item.id, form }))
          .unwrap()
          .then(() => {
            dispatch(
              setToastShow({
                toastShow: true,
                toastIsError: false,
                toastMessage: `${item.name} updated`,
              }),
            );
            return onSave(true);
          })
          .catch((err) => {
            dispatch(
              setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
            );
            return onSave(false);
          });
      }
    }
  };

  return (
    <Modal show={modal}>
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6">
            <h3 className="mb-5 text-center text-lg font-normal text-gray-500 dark:text-gray-400 capitalize">
              {modalStatus}
            </h3>
            <Select
              className="mb-2"
              label="Category*"
              id="menuCategory"
              name="menu_category_id"
              value={form.menu_category_id}
              touched={touched.menu_category_id}
              handleChange={handleFormChange}
              lists={
                categoriesAll?.data
                  ? selectList.concat(
                    categoriesAll?.data.map((x) => ({ value: x.id, label: x.name })),
                  )
                  : selectList
              }
              validation={error?.menu_category_id}
            />
            <Input
              id="menuName"
              type="text"
              name="name"
              value={form.name}
              touched={touched.name}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.name}
              label="Name*"
              placeholder="menu name"
            />
            <Input
              id="menuPrice"
              type="number"
              name="price"
              value={form.price}
              touched={touched.price}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.price}
              label="Price*"
              placeholder="menu amount"
            />
            {renderOptions()}
            <TextArea
              id="menuPhoto"
              name="photo_url"
              value={form.photo_url}
              touched={touched.photo_url}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.photo_url}
              label="Photo Url"
              placeholder="photo url"
            />
            {modalStatus === 'update' && (
              <Select
                label="Is Available?"
                id="menuIsAvailable"
                name="is_available"
                value={form.is_available}
                handleChange={handleFormChange}
                lists={isAvailableOptions}
              />
            )}
            <div className="flex justify-center mt-4">
              <Button
                name="saveModal"
                className="text-white bg-red-600 hover:bg-red-800"
                isLoading={menusLoading}
                isDisabled={menusLoading}
                handleClick={() => {
                  onSaveForm();
                }}
              >
                Save
              </Button>

              <Button
                name="closeModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  initForm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
  initTouched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default ActionModal;
