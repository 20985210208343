import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function ReviewCard(props) {
  const { item } = props;
  return (
    <div className="w-full max-w-sm bg-gray-50 rounded-lg shadow-lg">
      <div className="px-5 pb-5">
        <div className="flex items-center mt-2.5 mb-5">
          {[...Array(item.rating).keys()].map((e) => (
            <svg
              key={`${item.id}-star-${e}`}
              aria-hidden="true"
              className="w-5 h-5 text-yellow-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
          <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ml-3">
            {item.rating}
            .0
          </span>
        </div>
        <div className="flex justify-between items-center border-b border-dashed border-b-gray-900">
          <span className="text-3xl font-bold text-gray-900">
            {item.user.fullname}
          </span>
        </div>
        <p className="mt-2 text-gray-400 text-xs">{moment(item.updated_at).format('DD MMMM YYYY HH:mm')}</p>
        <h5 className="mt-2 text-lg tracking-tight text-gray-700">
          {item.description}
        </h5>
      </div>
    </div>
  );
}

ReviewCard.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
};

export default ReviewCard;
