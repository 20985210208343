import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import toastReducer from './slices/toastSlice';
import categoriesReducer from './slices/menuCategorySlice';
import couponsReducer from './slices/couponSlice';
import menusReducer from './slices/menuSlice';
import reviewsReducer from './slices/reviewSlice';
import ordersReducer from './slices/orderSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    categories: categoriesReducer,
    coupons: couponsReducer,
    menus: menusReducer,
    reviews: reviewsReducer,
    orders: ordersReducer,
  },
});

export default store;
