import { useEffect, useState } from 'react';

const useMenuForm = () => {
  const [form, setForm] = useState({
    name: '',
    price: '',
    options: [],
    photo_url: '',
    menu_category_id: '',
    is_available: '',
  });
  const [touched, setTouched] = useState({
    name: false,
    price: false,
    options: false,
    photo_url: false,
    menu_category_id: false,
    is_available: false,
  });
  const [error, setError] = useState({});

  const validate = () => {
    const errors = {};

    if (!form.name) {
      errors.name = 'Please input name';
    }

    if (!form.price) {
      errors.price = 'Please input price';
    } else if (parseInt(form.price, 10) <= 0) {
      errors.price = 'Please input valid price';
    }

    if (!form.menu_category_id) {
      errors.menu_category_id = 'Please input category';
    }

    if (form.options.length > 0) {
      form.options.forEach((x) => {
        x.options.forEach((option) => {
          if (option.value === '') {
            errors.options = 'Please check input options';
          }

          if (option.additional_price === '') {
            errors.options = 'Please check price input options';
          } else if (option.additional_price < 0) {
            errors.options = 'Please check price input options';
          }
        });
      });
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = (status) => {
    setTouched({
      name: true,
      price: true,
      options: true,
      photo_url: true,
      menu_category_id: true,
      is_available: true,
    });
    return validate(status);
  };

  return {
    form,
    error,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
    setForm,
    setTouched,
  };
};

export default useMenuForm;
