import { useEffect, useState } from 'react';

const useLoginForm = () => {
  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const [touched, setTouched] = useState({
    username: false,
    password: false,
  });
  const [error, setError] = useState({});

  const validate = () => {
    const errors = {};

    if (!form.username) {
      errors.username = 'Please input your username';
    }

    if (!form.password) {
      errors.password = 'Please input your password';
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = () => {
    setTouched({
      username: true,
      password: true,
    });
    return validate();
  };

  return {
    form,
    error,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
  };
};

export default useLoginForm;
