import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchMenus = createAsyncThunk('menus/fetch', async ({
  search, page, sort, sortBy,
}) => client
  .get(`/admin/menus/paginate?page=${page}&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const fetchMenu = createAsyncThunk('menus/fetchByID', async ({
  id,
}) => client
  .get(`/admin/menus/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createMenus = createAsyncThunk('menus/create', async ({ form }) => client
  .post('/admin/menus', JSON.stringify({
    name: form.name,
    price: parseInt(form.price, 10),
    photo_url: form.photo_url,
    options: JSON.stringify(form.options),
    menu_category_id: form.menu_category_id,
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateMenus = createAsyncThunk('menus/update', async ({ id, form }) => client
  .put(`/admin/menus/${id}`, JSON.stringify({
    name: form.name,
    price: parseInt(form.price, 10),
    photo_url: form.photo_url,
    options: JSON.stringify(form.options),
    menu_category_id: form.menu_category_id,
    is_available: (form.is_available === 'true'),
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const deleteMenus = createAsyncThunk('menus/delete', async ({ id }) => client
  .delete(`/admin/menus/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  menus: [],
  menu: {},
  menusError: null,
  menusLoading: false,
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state, { payload }) => ({
      ...state,
      menus: payload,
      menusLoading: false,
    }));
    builder.addCase(fetchMenus.pending, (state) => ({
      ...state,
      menusError: null,
      menusLoading: true,
    }));
    builder.addCase(fetchMenus.rejected, (state, { error }) => ({
      ...state,
      menusError: error.message,
      menusLoading: false,
    }));
    builder.addCase(fetchMenu.fulfilled, (state, { payload }) => ({
      ...state,
      menu: payload,
      menusLoading: false,
    }));
    builder.addCase(fetchMenu.pending, (state) => ({
      ...state,
      menusError: null,
      menusLoading: true,
    }));
    builder.addCase(fetchMenu.rejected, (state, { error }) => ({
      ...state,
      menusError: error.message,
      menusLoading: false,
    }));
    builder.addCase(deleteMenus.fulfilled, (state) => ({
      ...state,
      menusLoading: false,
    }));
    builder.addCase(deleteMenus.pending, (state) => ({
      ...state,
      menusLoading: true,
    }));
    builder.addCase(deleteMenus.rejected, (state) => ({
      ...state,
      menusLoading: false,
    }));
    builder.addCase(createMenus.fulfilled, (state) => ({
      ...state,
      menusLoading: false,
    }));
    builder.addCase(createMenus.pending, (state) => ({
      ...state,
      menusLoading: true,
    }));
    builder.addCase(createMenus.rejected, (state) => ({
      ...state,
      menusLoading: false,
    }));
    builder.addCase(updateMenus.fulfilled, (state) => ({
      ...state,
      menusLoading: false,
    }));
    builder.addCase(updateMenus.pending, (state) => ({
      ...state,
      menusLoading: true,
    }));
    builder.addCase(updateMenus.rejected, (state) => ({
      ...state,
      menusLoading: false,
    }));
  },
});

export default menusSlice.reducer;
