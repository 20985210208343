import React, { useEffect } from 'react';
import './App.css';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './pages/Login';
import Toast from './components/Toast';
import AdminLayout from './layouts/AdminLayout';
import NotFound from './pages/NotFound';
import MenuCategory from './pages/MenuCategory';
import Coupon from './pages/Coupon';
import Menu from './pages/Menu';
import Review from './pages/Review';
import Order from './pages/Order';
import Home from './pages/Home';

function App() {
  return (
    <>
      <Toast />
      <main>
        <Routes>
          <Route
            path="/"
            element={(
              <RequireAuth redirectTo="/login">
                <AdminLayout />
              </RequireAuth>
            )}
          >
            <Route index element={<Home />} />
            <Route path="/menu-categories" element={<MenuCategory />} />
            <Route path="/coupons" element={<Coupon />} />
            <Route path="/orders" element={<Order />} />
            <Route path="/menus" element={<Menu />} />
            <Route path="/menus/:id/reviews" element={<Review />} />
          </Route>
          <Route>
            <Route
              path="/login"
              element={(
                <AlreadyAuth redirectTo="/">
                  <Login />
                </AlreadyAuth>
              )}
            />
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </>
  );
}

const RequireAuth = ({ redirectTo, children }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  useEffect(() => {
    const token = Cookies.get('TOKEN');
    if (!token) {
      navigate(redirectTo, {
        replace: true,
        state: { from: loc.pathname },
      });
    }
  });

  return children;
};

const AlreadyAuth = ({ redirectTo, children }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  useEffect(() => {
    const token = Cookies.get('TOKEN');
    if (token) {
      navigate(redirectTo, {
        replace: true,
        state: { from: loc.pathname },
      });
    }
  });

  return children;
};

export default App;
