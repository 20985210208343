import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../assets/svgs/loader.svg';

function Button(props) {
  const {
    className, name, isLoading, isDisabled, handleClick, children,
  } = props;
  return (
    <button
      className={`${className} ${isLoading || isDisabled ? 'cursor-not-allowed bg-gray-300 hover:bg-gray-300' : ''} w-full text-white bg-red-500 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none`}
      type="button"
      name={name}
      onClick={handleClick}
      disabled={isLoading || isDisabled}
    >
      <div className={`${isLoading ? 'block ' : 'hidden '} flex justify-center`}>
        <img className="h-5" src={loader} alt="loader" />
      </div>
      <div className={`${isLoading ? 'hidden ' : 'block '}`}>
        <p>{children}</p>
      </div>
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  isLoading: false,
  isDisabled: false,
  className: '',
};

export default Button;
