import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import noData from '../../assets/svgs/no-data.svg';
import Container from '../../components/Container';
import Select from '../../components/Select';
import Table from '../../components/Table';
import convertToRupiah from '../../helpers/convertToRupiah';
import { deleteMenus, fetchMenus } from '../../stores/slices/menuSlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import ActionModal from './components/ActionModal';
import DeleteModal from './components/DeleteModal';
import useMenuForm from './index.hooks';

function Menu() {
  const navigate = useNavigate();
  const { form, setForm, setTouched } = useMenuForm();

  const initTouched = {
    name: false,
    price: false,
    options: false,
    photo_url: false,
    menu_category_id: false,
    is_available: false,
  };

  const { menus, menusLoading, menusError } = useSelector((state) => state.menus);
  const dispatch = useDispatch();

  const [item, setItem] = useState({});
  const [sort, setSort] = useState('desc');
  const [sortBy, setSortBy] = useState('updated_at');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalStatus, setModalStatus] = useState('create');

  useEffect(() => {
    dispatch(
      fetchMenus({
        page,
        search,
        sort,
        sortBy,
      }),
    );
  }, [page, search, sort, sortBy]);

  const rows = [
    {
      title: 'Photo',
      column: 'photo_url',
    },
    {
      title: 'Name',
      column: 'name',
    },
    {
      title: 'Avg Rating',
      column: 'avg_rating',
    },
    {
      title: 'Price',
      column: 'price',
    },
    {
      title: 'Is Available',
      column: 'is_available',
    },
    {
      title: 'Updated at',
      column: 'updated_at',
    },
    {
      title: 'Action',
      column: '',
    },
  ];

  const sortByOptions = [
    { value: 'name', label: 'Name' },
    { value: 'price', label: 'Price' },
    { value: 'avg_rating', label: 'Avg Rating' },
    { value: 'is_available', label: 'Is Available' },
    { value: 'updated_at', label: 'Updated At' },
  ];

  const sortOptions = [
    { value: 'desc', label: 'Descending' },
    { value: 'asc', label: 'Ascending' },
  ];

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const onPreviousClick = () => {
    setPage((prevState) => prevState - 1);
  };

  const onNextClick = () => {
    setPage((prevState) => prevState + 1);
  };

  const handleSearch = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setSearch(e.target.value);
  };

  const onCreateClick = () => {
    setForm({
      name: '',
      price: '',
      options: '',
      photo_url: '',
      menu_category_id: '',
      is_available: false,
    });
    setTouched({
      name: false,
      price: false,
      options: false,
      photo_url: false,
      menu_category_id: false,
      is_available: false,
    });
    setModal(true);
    setModalStatus('create');
  };

  const onEditClick = (id) => {
    const editItem = menus?.data?.data.find((x) => x.id === id);
    setForm({
      name: editItem.name,
      price: editItem.price,
      options: JSON.parse(editItem.options),
      photo_url: editItem.photo_url,
      menu_category_id: editItem.menu_category_id,
      is_available: editItem.is_available === true ? 'true' : 'false',
    });
    setTouched({
      name: false,
      price: false,
      options: false,
      photo_url: false,
      menu_category_id: false,
      is_available: false,
    });
    setItem(editItem);
    setModal(true);
    setModalStatus('update');
  };

  const onDeleteClick = (id) => {
    const editItem = menus?.data?.data.find((x) => x.id === id);
    setItem(editItem);
    setModalDelete(true);
  };

  const onReviewClick = (id) => {
    navigate(`/menus/${id}/reviews`);
  };

  const onCloseDelete = () => {
    setModalDelete(false);
  };

  const onCloseModal = () => {
    setModal(false);
    setModalStatus('create');
  };

  const onSave = (status) => {
    if (status) {
      dispatch(
        fetchMenus({
          page,
          search,
          sort,
          sortBy,
        }),
      );
    }
    setModal(false);
  };

  const onDelete = () => {
    dispatch(deleteMenus({ id: item.id }))
      .unwrap()
      .then(() => {
        dispatch(
          setToastShow({
            toastShow: true,
            toastIsError: false,
            toastMessage: `${item.name} deleted`,
          }),
        );
        dispatch(
          fetchMenus({
            page,
            search,
            sort,
            sortBy,
          }),
        );
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      })
      .finally(() => {
        setModalDelete(false);
      });
  };

  const renderData = () => {
    if (menus?.data?.data == null) {
      return (
        <tr>
          <td colSpan={rows.length} className="py-8">
            <img className="w-[80px] mx-auto" src={noData} alt="no data" />
            <p className="text-center mt-4">No Data</p>
          </td>
        </tr>
      );
    }

    return menus?.data?.data.map((itemData) => (
      <tr key={itemData.id} className="bg-white border-b hover:bg-gray-50">
        <td className="py-4 px-6"><img className="object-cover w-40" src={itemData.photo_url} alt={item.name} /></td>
        <td className="py-4 px-6">{itemData.name}</td>
        <td className="py-4 px-6">{itemData.avg_rating}</td>
        <td className="py-4 px-6">{`Rp. ${convertToRupiah(itemData.price)}`}</td>
        <td
          className={`py-4 px-6 capitalize ${
            itemData.is_available ? 'text-green-400 ' : 'text-red-400 '
          }`}
        >
          {`${itemData.is_available}`}
        </td>
        <td className="py-4 px-6">{moment(itemData.updated_at).format('HH:mm - DD MMMM YYYY')}</td>
        <td className="py-4 px-6">
          <button
            type="button"
            className="font-medium text-blue-600 hover:underline mr-4"
            onClick={() => {
              onEditClick(itemData.id);
            }}
          >
            Edit
          </button>
          <button
            type="button"
            className="font-medium text-red-600 hover:underline mr-4"
            onClick={() => {
              onDeleteClick(itemData.id);
            }}
          >
            Delete
          </button>
          <button
            type="button"
            className="font-medium text-gray-600 hover:underline"
            onClick={() => {
              onReviewClick(itemData.id);
            }}
          >
            Reviews
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      <h1 className="mt-10 text-3xl font-bold">Menu</h1>
      <button
        data-modal-toggle="popup-modal"
        type="button"
        className="mt-4 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
        onClick={onCreateClick}
      >
        Add New
      </button>
      <DeleteModal
        modalDelete={modalDelete}
        onCloseDelete={onCloseDelete}
        onDelete={onDelete}
        item={item}
      />
      <ActionModal
        modal={modal}
        onCloseModal={onCloseModal}
        modalStatus={modalStatus}
        onSave={onSave}
        initForm={form}
        item={item}
        initTouched={initTouched}
      />
      <div className="mt-8">
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div className="flex flex-col gap-y-4 pl-2 md:pl-0 md:flex-row justify-start md:justify-between items-start md:items-center pb-4 bg-white dark:bg-gray-900">
            <div className="flex gap-2">
              <p className="mr-4 mt-2">Sort</p>
              <Select
                id="sortBy"
                name="sortBy"
                value={sortBy}
                handleChange={handleSortByChange}
                lists={sortByOptions}
              />
              <Select
                id="sort"
                name="sort"
                value={sort}
                handleChange={handleSortChange}
                lists={sortOptions}
              />
            </div>
            <div className="relative">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block p-2 pl-10 max-w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
          <Table
            rows={rows}
            renderData={renderData}
            page={menus?.data?.page}
            total={menus?.data?.total_pages}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            isLoading={menusLoading}
            error={menusError}
          />
        </div>
      </div>
    </Container>
  );
}

export default Menu;
