import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchReviews = createAsyncThunk('reviews/fetch', async ({
  id,
}) => client
  .get(`/admin/menus/${id}/reviews`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  reviews: [],
  reviewsError: null,
  reviewsLoading: false,
};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => ({
      ...state,
      reviews: payload.data,
      reviewsLoading: false,
    }));
    builder.addCase(fetchReviews.pending, (state) => ({
      ...state,
      reviewsError: null,
      reviewsLoading: true,
    }));
    builder.addCase(fetchReviews.rejected, (state, { error }) => ({
      ...state,
      reviewsError: error.message,
      reviewsLoading: false,
    }));
  },
});

export default reviewsSlice.reducer;
