import React from 'react';
import PropTypes from 'prop-types';

function TextArea(props) {
  const {
    id,
    className,
    name,
    label,
    placeholder,
    isDisabled,
    validation,
    handleInputChange,
    handleOnBlur,
    value,
    touched,
  } = props;
  return (
    <div className="mb-6">
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 ">
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        className={`${className} border text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
          validation ? ' border-red-400' : ' border-gray-300'
        } ${isDisabled ? ' bg-gray-50' : 'bg-white'}`}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        value={value}
      />
      <span className={`${validation && touched ? 'block ' : 'hidden '} text-red-400`}>
        {validation}
      </span>
    </div>
  );
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  validation: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func,
  className: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

TextArea.defaultProps = {
  label: '',
  isDisabled: false,
  validation: '',
  className: '',
  touched: false,
  value: '',
  handleOnBlur: () => {},
};

export default TextArea;
