import React, { useState } from 'react';
import PropTypes from 'prop-types';
import eye from '../../assets/svgs/eye.svg';
import eyeOff from '../../assets/svgs/eye-off.svg';

function Input(props) {
  const {
    id,
    type,
    className,
    name,
    label,
    touched,
    placeholder,
    isDisabled,
    validation,
    handleInputChange,
    handleOnBlur,
    value,
  } = props;
  const [inputType, setInputType] = useState(type);

  const handleIconClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };
  return (
    <div className="mb-2 relative">
      {label ? (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      ) : null}
      <input
        type={inputType}
        id={id}
        name={name}
        className={`${className} border text-gray-900 text-sm rounded-lg block w-full p-2.5  ${
          validation && touched ? ' border-red-400' : ' border-gray-300'
        } ${isDisabled ? ' bg-gray-50' : 'bg-white'}`}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        value={value}
      />
      <div
        className={`${
          id.includes('password') ? 'absolute ' : 'hidden '
        }absolute right-0 top-9 mr-3`}
        onClick={handleIconClick}
        aria-hidden="true"
      >
        <img src={inputType === 'password' ? eyeOff : eye} alt="password-icon" />
      </div>
      <span className={`${validation && touched ? 'block ' : 'hidden '} text-red-400`}>
        {validation}
      </span>
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  validation: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func,
  className: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

Input.defaultProps = {
  label: '',
  isDisabled: false,
  validation: '',
  className: '',
  touched: false,
  value: '',
  handleOnBlur: () => {},
};

export default Input;
