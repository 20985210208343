import React from 'react';
import PropTypes from 'prop-types';
import loading from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';

function Table(props) {
  const {
    rows, renderData, page, total, onPreviousClick, onNextClick, isLoading, error, footer,
  } = props;

  return (
    <>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {rows.map((row) => (
              <th key={row.title} scope="col" className="py-3 px-6">
                <div className="flex items-start">{row.title}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={rows.length} className="py-8">
                <img className="w-[80px] mx-auto" src={loading} alt="no data" />
                <p className="text-center mt-4">Loading</p>
              </td>
            </tr>
          )}
          {error && (
            <tr>
              <td colSpan={rows.length} className="py-8">
                <img className="w-[80px] mx-auto" src={noData} alt="no data" />
                <p className="text-center mt-4">Something went wrong</p>
              </td>
            </tr>
          )}
          {!isLoading && !error && renderData()}
        </tbody>
      </table>
      {footer && (
        <>
          <p className="pl-4 pt-4">
            Showing
            {' '}
            {page}
            {' '}
            of
            {' '}
            {total}
            {' '}
            pages
          </p>
          <nav className="flex justify-start items-center p-4" aria-label="Table navigation">
            <button
              type="button"
              className={`${
                parseInt(page, 10) <= 1
                  ? 'cursor-not-allowed bg-gray-200 '
                  : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700 bg-white '
              } inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 rounded-lg border border-gray-300`}
              disabled={parseInt(page, 10) <= 1}
              onClick={onPreviousClick}
            >
              Previous
            </button>

            <button
              type="button"
              className={`${
                parseInt(page, 10) >= total || total === 0
                  ? 'cursor-not-allowed bg-gray-200 '
                  : 'cursor-pointer hover:bg-gray-100 hover:text-gray-700 bg-white '
              } inline-flex items-center ml-2 py-2 px-4 text-sm font-medium text-gray-500 rounded-lg border border-gray-300`}
              disabled={parseInt(page, 10) >= total || total === 0}
              onClick={onNextClick}
            >
              Next
            </button>
          </nav>
        </>
      )}
    </>
  );
}

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
  renderData: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  page: PropTypes.string,
  total: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  footer: PropTypes.bool,
};

Table.defaultProps = {
  page: '0',
  total: 0,
  error: '',
  footer: true,
};

export default Table;
