import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noData from '../../assets/svgs/no-data.svg';
import Container from '../../components/Container';
import Select from '../../components/Select';
import Table from '../../components/Table';
import {
  deleteCategories,
  fetchCategories,
} from '../../stores/slices/menuCategorySlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import ActionModal from './components/ActionModal';
import DeleteModal from './components/DeleteModal';
import useMenuCategoryForm from './index.hooks';

function MenuCategory() {
  const {
    form,
    setForm,
    setTouched,
  } = useMenuCategoryForm();

  const initTouched = {
    name: false,
  };

  const { categories, categoriesLoading, categoriesError } = useSelector(
    (state) => state.categories,
  );
  const dispatch = useDispatch();

  const [item, setItem] = useState({});
  const [sort, setSort] = useState('desc');
  const [sortBy, setSortBy] = useState('updated_at');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalStatus, setModalStatus] = useState('create');

  useEffect(() => {
    dispatch(
      fetchCategories({
        page,
        search,
        sort,
        sortBy,
      }),
    );
  }, [page, search, sort, sortBy]);

  const rows = [
    {
      title: 'Name',
      column: 'name',
      sortable: true,
      sortAsc: true,
    },
    {
      title: 'Updated at',
      column: 'updated_at',
      sortable: true,
      sortAsc: true,
    },
    {
      title: 'Action',
      column: '',
      sortable: false,
      sortAsc: true,
    },
  ];

  const sortByOptions = [
    { value: 'name', label: 'Name' },
    { value: 'updated_at', label: 'Updated At' },
  ];

  const sortOptions = [
    { value: 'desc', label: 'Descending' },
    { value: 'asc', label: 'Ascending' },
  ];

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const onPreviousClick = () => {
    setPage((prevState) => prevState - 1);
  };

  const onNextClick = () => {
    setPage((prevState) => prevState + 1);
  };

  const handleSearch = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setSearch(e.target.value);
  };

  const onCreateClick = () => {
    setForm({ name: '' });
    setTouched({ name: false });
    setModal(true);
    setModalStatus('create');
  };

  const onEditClick = (id) => {
    const editItem = categories?.data?.data.find((x) => x.id === id);
    setForm({ name: editItem.name });
    setTouched({ name: false });
    setItem(editItem);
    setModal(true);
    setModalStatus('update');
  };

  const onDeleteClick = (id) => {
    const editItem = categories?.data?.data.find((x) => x.id === id);
    setItem(editItem);
    setModalDelete(true);
  };

  const onCloseDelete = () => {
    setModalDelete(false);
  };

  const onCloseModal = () => {
    setModal(false);
    setModalStatus('create');
  };

  const onSave = (status) => {
    if (status) {
      dispatch(
        fetchCategories({
          page,
          search,
          sort,
          sortBy,
        }),
      );
    }
    setModal(false);

    // if (validateForm()) {
    //   if (modalStatus === 'create') {
    //     dispatch(createCategories({ form }))
    //       .unwrap()
    //       .then(() => {
    //         dispatch(
    //           setToastShow({
    //             toastShow: true,
    //             toastIsError: false,
    //             toastMessage: `${form.name} created`,
    //           }),
    //         );
    //         dispatch(
    //           fetchCategories({
    //             page,
    //             search,
    //             sort,
    //             sortBy,
    //           }),
    //         );
    //       })
    //       .catch((err) => {
    //         dispatch(
    //           setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
    //         );
    //       })
    //       .finally(() => {
    //         setModal(false);
    //       });
    //   } else {
    //     dispatch(updateCategories({ id: item.id, form }))
    //       .unwrap()
    //       .then(() => {
    //         dispatch(
    //           setToastShow({
    //             toastShow: true,
    //             toastIsError: false,
    //             toastMessage: `${item.name} updated`,
    //           }),
    //         );
    //         dispatch(
    //           fetchCategories({
    //             page,
    //             search,
    //             sort,
    //             sortBy,
    //           }),
    //         );
    //       })
    //       .catch((err) => {
    //         dispatch(
    //           setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
    //         );
    //       })
    //       .finally(() => {
    //         setModal(false);
    //       });
    //   }
    // }
  };
  const onDelete = () => {
    dispatch(deleteCategories({ id: item.id }))
      .unwrap()
      .then(() => {
        dispatch(
          setToastShow({
            toastShow: true,
            toastIsError: false,
            toastMessage: `${item.name} deleted`,
          }),
        );
        dispatch(
          fetchCategories({
            page,
            search,
            sort,
            sortBy,
          }),
        );
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      })
      .finally(() => {
        setModalDelete(false);
      });
  };

  const renderData = () => {
    if (categories?.data?.data == null) {
      return (
        <tr>
          <td colSpan={rows.length} className="py-8">
            <img className="w-[80px] mx-auto" src={noData} alt="no data" />
            <p className="text-center mt-4">No Data</p>
          </td>
        </tr>
      );
    }

    return categories?.data?.data.map((itemData) => (
      <tr key={itemData.id} className="bg-white border-b hover:bg-gray-50">
        <td className="py-4 px-6">{itemData.name}</td>
        <td className="py-4 px-6">{moment(itemData.updated_at).format('HH:mm - DD MMMM YYYY')}</td>
        <td className="py-4 px-6">
          <button
            type="button"
            className="font-medium text-blue-600 hover:underline mr-4"
            onClick={() => {
              onEditClick(itemData.id);
            }}
          >
            Edit
          </button>
          <button
            type="button"
            className="font-medium text-red-600 hover:underline"
            onClick={() => {
              onDeleteClick(itemData.id);
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      <h1 className="mt-10 text-3xl font-bold">Menu Category</h1>
      <button
        data-modal-toggle="popup-modal"
        type="button"
        className="mt-4 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
        onClick={onCreateClick}
      >
        Add New
      </button>
      <DeleteModal
        modalDelete={modalDelete}
        onCloseDelete={onCloseDelete}
        onDelete={onDelete}
        item={item}
      />
      <ActionModal
        modal={modal}
        onCloseModal={onCloseModal}
        modalStatus={modalStatus}
        onSave={onSave}
        initForm={form}
        item={item}
        initTouched={initTouched}
      />
      <div className="mt-8">
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div className="flex flex-col gap-y-4 pl-2 md:pl-0 md:flex-row justify-start md:justify-between items-start md:items-center pb-4 bg-white dark:bg-gray-900">
            <div className="flex gap-2">
              <p className="mr-4 mt-2">Sort</p>
              <Select
                id="sortBy"
                name="sortBy"
                value={sortBy}
                handleChange={handleSortByChange}
                lists={sortByOptions}
              />
              <Select
                id="sort"
                name="sort"
                value={sort}
                handleChange={handleSortChange}
                lists={sortOptions}
              />
            </div>
            <div className="relative">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block p-2 pl-10 max-w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
          <Table
            rows={rows}
            renderData={renderData}
            page={categories?.data?.page}
            total={categories?.data?.total_pages}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            isLoading={categoriesLoading}
            error={categoriesError}
          />
        </div>
      </div>
    </Container>
  );
}

export default MenuCategory;
