import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchCategories = createAsyncThunk('categories/fetch', async ({
  search, page, sort, sortBy,
}) => client
  .get(`/admin/categories/paginate?page=${page}&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const fetchAllCategories = createAsyncThunk('categories/fetchAll', async ({
  search, sort, sortBy,
}) => client
  .get(`/admin/categories?&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createCategories = createAsyncThunk('categories/create', async ({ form }) => client
  .post('/admin/categories', JSON.stringify({
    name: form.name,
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateCategories = createAsyncThunk('categories/update', async ({ id, form }) => client
  .put(`/admin/categories/${id}`, JSON.stringify({
    name: form.name,
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const deleteCategories = createAsyncThunk('categories/delete', async ({ id }) => client
  .delete(`/admin/categories/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  categories: [],
  categoriesAll: [],
  categoriesError: null,
  categoriesLoading: false,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => ({
      ...state,
      categories: payload,
      categoriesLoading: false,
    }));
    builder.addCase(fetchCategories.pending, (state) => ({
      ...state,
      categoriesError: null,
      categoriesLoading: true,
    }));
    builder.addCase(fetchCategories.rejected, (state, { error }) => ({
      ...state,
      categoriesError: error.message,
      categoriesLoading: false,
    }));
    builder.addCase(fetchAllCategories.fulfilled, (state, { payload }) => ({
      ...state,
      categoriesAll: payload,
      categoriesLoading: false,
    }));
    builder.addCase(fetchAllCategories.pending, (state) => ({
      ...state,
      categoriesError: null,
      categoriesLoading: true,
    }));
    builder.addCase(fetchAllCategories.rejected, (state, { error }) => ({
      ...state,
      categoriesError: error.message,
      categoriesLoading: false,
    }));
    builder.addCase(deleteCategories.fulfilled, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
    builder.addCase(deleteCategories.pending, (state) => ({
      ...state,
      categoriesLoading: true,
    }));
    builder.addCase(deleteCategories.rejected, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
    builder.addCase(createCategories.fulfilled, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
    builder.addCase(createCategories.pending, (state) => ({
      ...state,
      categoriesLoading: true,
    }));
    builder.addCase(createCategories.rejected, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
    builder.addCase(updateCategories.fulfilled, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
    builder.addCase(updateCategories.pending, (state) => ({
      ...state,
      categoriesLoading: true,
    }));
    builder.addCase(updateCategories.rejected, (state) => ({
      ...state,
      categoriesLoading: false,
    }));
  },
});

export default categoriesSlice.reducer;
