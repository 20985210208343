import React from 'react';
import PropTypes from 'prop-types';

function Select(props) {
  const {
    id, className, label, name, lists, value, handleChange, validation, touched, handleOnBlur,
  } = props;

  const renderList = () => lists.map((list) => (
    <option key={list.value} value={list.value}>
      {list.label}
    </option>
  ));

  return (
    <div className={`${className}`}>
      {label ? (
        <label htmlFor="from" className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      ) : null}

      <select
        id={id}
        name={name}
        className={`${
          validation && touched ? ' border-red-400' : ' border-gray-300'
        } bg-white border text-gray-900 text-sm rounded-lg block w-full p-2.5`}
        onChange={handleChange}
        onBlur={handleOnBlur}
        value={value}
      >
        {renderList()}
      </select>
      <span className={`${validation && touched ? 'block ' : 'hidden '} text-red-400`}>
        {validation}
      </span>
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  lists: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string, PropTypes.bool]),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  validation: PropTypes.string,
  handleOnBlur: PropTypes.func,
  touched: PropTypes.bool,
};

Select.defaultProps = {
  value: '',
  label: '',
  validation: '',
  className: '',
  touched: false,
  handleChange: () => {},
  handleOnBlur: () => {},
};

export default Select;
