import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchCoupons = createAsyncThunk('coupons/fetch', async ({
  search, page, sort, sortBy,
}) => client
  .get(`/admin/coupons/paginate?page=${page}&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createCoupons = createAsyncThunk('coupons/create', async ({ form }) => client
  .post('/admin/coupons', JSON.stringify({
    name: form.name,
    amount: parseInt(form.amount, 10),
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateCoupons = createAsyncThunk('coupons/update', async ({ id, form }) => client
  .put(`/admin/coupons/${id}`, JSON.stringify({
    name: form.name,
    amount: parseInt(form.amount, 10),
    is_available: (form.is_available === 'true'),
  }))
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const deleteCoupons = createAsyncThunk('coupons/delete', async ({ id }) => client
  .delete(`/admin/coupons/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  coupons: [],
  couponsError: null,
  couponsLoading: false,
};

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCoupons.fulfilled, (state, { payload }) => ({
      ...state,
      coupons: payload,
      couponsLoading: false,
    }));
    builder.addCase(fetchCoupons.pending, (state) => ({
      ...state,
      couponsError: null,
      couponsLoading: true,
    }));
    builder.addCase(fetchCoupons.rejected, (state, { error }) => ({
      ...state,
      couponsError: error.message,
      couponsLoading: false,
    }));
    builder.addCase(deleteCoupons.fulfilled, (state) => ({
      ...state,
      couponsLoading: false,
    }));
    builder.addCase(deleteCoupons.pending, (state) => ({
      ...state,
      couponsLoading: true,
    }));
    builder.addCase(deleteCoupons.rejected, (state) => ({
      ...state,
      couponsLoading: false,
    }));
    builder.addCase(createCoupons.fulfilled, (state) => ({
      ...state,
      couponsLoading: false,
    }));
    builder.addCase(createCoupons.pending, (state) => ({
      ...state,
      couponsLoading: true,
    }));
    builder.addCase(createCoupons.rejected, (state) => ({
      ...state,
      couponsLoading: false,
    }));
    builder.addCase(updateCoupons.fulfilled, (state) => ({
      ...state,
      couponsLoading: false,
    }));
    builder.addCase(updateCoupons.pending, (state) => ({
      ...state,
      couponsLoading: true,
    }));
    builder.addCase(updateCoupons.rejected, (state) => ({
      ...state,
      couponsLoading: false,
    }));
  },
});

export default couponsSlice.reducer;
