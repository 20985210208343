import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

function AdminLayout() {
  return (
    <div className="relative flex min-h-screen">
      <Sidebar />
      <div className="w-full overflow-x-scroll">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
