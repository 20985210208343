import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import useMenuCategoryForm from '../../index.hooks';
import { createCategories, updateCategories } from '../../../../stores/slices/menuCategorySlice';
import { setToastShow } from '../../../../stores/slices/toastSlice';
import Button from '../../../../components/Button';

function ActionModal(props) {
  const dispatch = useDispatch();
  const {
    modal, onCloseModal, modalStatus, onSave, initForm, item, initTouched,
  } = props;

  const {
    form,
    setForm,
    error,
    touched,
    setTouched,
    handleOnBlur,
    handleFormChange,
    validateForm,
  } = useMenuCategoryForm();

  const { categoriesLoading } = useSelector((state) => state.categories);

  useEffect(() => {
    setForm(initForm);
    setTouched(initTouched);
  }, [initForm]);

  const onSaveForm = () => {
    if (validateForm()) {
      if (modalStatus === 'create') {
        dispatch(createCategories({ form }))
          .unwrap()
          .then(() => {
            dispatch(
              setToastShow({
                toastShow: true,
                toastIsError: false,
                toastMessage: `${form.name} created`,
              }),
            );
            return onSave(true);
          })
          .catch((err) => {
            dispatch(
              setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
            );
            return onSave(false);
          });
      } else {
        dispatch(updateCategories({ id: item.id, form }))
          .unwrap()
          .then(() => {
            dispatch(
              setToastShow({
                toastShow: true,
                toastIsError: false,
                toastMessage: `${item.name} updated`,
              }),
            );
            return onSave(true);
          })
          .catch((err) => {
            dispatch(
              setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
            );
            return onSave(false);
          });
      }
    }
  };

  return (
    <Modal show={modal}>
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6">
            <h3 className="mb-5 text-center text-lg font-normal text-gray-500 dark:text-gray-400 capitalize">
              {modalStatus}
            </h3>
            <Input
              id="categoryName"
              type="text"
              name="name"
              value={form.name}
              touched={touched.name}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.name}
              label="Name*"
              placeholder="category name"
            />
            <div className="flex justify-center">
              <Button
                name="saveModal"
                className="text-white bg-red-600 hover:bg-red-800"
                isLoading={categoriesLoading}
                isDisabled={categoriesLoading}
                handleClick={() => {
                  onSaveForm();
                }}
              >
                Save
              </Button>

              <Button
                name="closeModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  initForm: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
  initTouched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ).isRequired,
};

export default ActionModal;
