import React from 'react';
import PropTypes from 'prop-types';

function Modal(props) {
  const { show, children } = props;
  return (
    <>
      <div
        tabIndex="-1"
        className={`${
          show ? 'block ' : 'hidden '
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full`}
      >
        {children}
      </div>
      {show && <div className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40" />}
    </>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Modal;
