import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toastShow: false,
  toastIsError: false,
  toastMessage: '',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastShow: (state, { payload }) => ({ ...state, ...payload }),
    setToastIsError: (state, action) => ({ ...state, toastIsError: action.payload }),
    setToastMessage: (state, action) => ({ ...state, toastMessage: action.payload }),
  },
});

export const { setToastShow, setToastIsError, setToastMessage } = toastSlice.actions;

export default toastSlice.reducer;
