import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import client from '../config/client';

export const login = createAsyncThunk('auth/login', (data) => {
  const {
    username, password,
  } = data;

  return client
    .post(
      '/admin/login',
      JSON.stringify({
        username,
        password,
      }),
    )
    .then((response) => {
      Cookies.set('TOKEN', response.data.data.token);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
});

const initialState = {
  authError: null,
  authLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => ({
      ...state,
      token: payload.data.token,
      authLoading: false,
    }));
    builder.addCase(login.pending, (state) => ({
      ...state,
      authError: null,
      authLoading: true,
    }));
    builder.addCase(login.rejected, (state, { error }) => ({
      ...state,
      authError: error.message,
      authLoading: false,
    }));
  },
});

export default authSlice.reducer;
