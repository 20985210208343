import { useEffect, useState } from 'react';

const useCouponForm = () => {
  const [form, setForm] = useState({
    name: '',
    amount: '',
    is_available: '',
  });
  const [touched, setTouched] = useState({
    name: false,
    amount: false,
    is_available: false,
  });
  const [error, setError] = useState({});

  const validate = (status) => {
    const errors = {};

    if (!form.name) {
      errors.name = 'Please input name';
    }

    if (!form.amount) {
      errors.amount = 'Please input amount';
    } else if (parseInt(form.amount, 10) <= 0) {
      errors.amount = 'Please input valid amount';
    }

    if (status === 'update') {
      if (!form.is_available) {
        error.is_available = 'Please input availability';
      }
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = (status) => {
    setTouched({
      name: true,
      amount: true,
      is_available: true,
    });
    return validate(status);
  };

  return {
    form,
    error,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
    setForm,
    setTouched,
  };
};

export default useCouponForm;
