import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import category from '../../assets/svgs/category.svg';
import menuIcon from '../../assets/svgs/menu.svg';
import coupon from '../../assets/svgs/coupon.svg';
import home from '../../assets/svgs/home.svg';
import order from '../../assets/svgs/order.svg';
import logout from '../../assets/svgs/logout.svg';
import { ReactComponent as CloseSidebar } from '../../assets/svgs/close-sidebar.svg';
import { ReactComponent as OpenSidebar } from '../../assets/svgs/open-sidebar.svg';

function Sidebar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const menus = [
    {
      link: '/',
      icon: home,
      name: 'Home',
    },
    {
      link: '/menu-categories',
      icon: category,
      name: 'Menu Categories',
    },
    {
      link: '/menus',
      icon: menuIcon,
      name: 'Menus',
    },
    {
      link: '/orders',
      icon: order,
      name: 'Orders',
    },
    {
      link: '/coupons',
      icon: coupon,
      name: 'Coupons',
    },
  ];

  const handleLogoutClick = () => {
    Cookies.remove('TOKEN');
    navigate('/login');
  };

  return (
    <div
      className={`${
        open ? 'w-[250px] ' : 'w-[60px]'
      } min-h-[100%] max-h-[100%] bg-zinc-200 pt-[28px] transition-all delay-300 ease-in-out`}
    >
      <button
        className="self-start justify-self-end bg-transparent border-0 cursor-pointer pl-[20px]"
        type="button"
        onClick={toggleOpen}
      >
        {open ? <CloseSidebar /> : <OpenSidebar />}
      </button>
      <div className="mt-8">
        {menus.map((menu) => {
          const location = useLocation();
          return (
            <Link
              key={menu.name}
              to={menu.link}
              className={`${
                location.pathname === menu.link ? 'border-b-2 border-gray-600 ' : ''
              } mb-2 flex items-center py-[10px] px-[20px] cursor-pointer overflow-hidden`}
            >
              <img className="mr-6" src={menu.icon} alt="menu-logo" />
              <p>{menu.name}</p>
            </Link>
          );
        })}
        <div
          className="mb-2 flex items-center py-[10px] px-[20px] cursor-pointer overflow-hidden"
          onClick={handleLogoutClick}
          role="button"
          onKeyPress={() => {}}
          tabIndex="0"
        >
          <img className="mr-6" src={logout} alt="menu-logo" />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
